import {createReducer, on} from '@ngrx/store';
import {INavigation} from '../interfaces/IPressUpStore';
import {navigationActions} from '../action/navigation-action';

export const navigationReducer = createReducer(
    {},
    on(navigationActions.setPreviousUrl, (state: INavigation, {previous}) => ({
        ...state,
        previous
    })),
    on(navigationActions.setCurrentUrl, (state: INavigation, {current}) => ({
        ...state,
        current
    })),
);
