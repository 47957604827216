import {createActionGroup, props} from '@ngrx/store';
import {StoreFeatureNames} from '../enums/store-feature-names';

enum NavigationAction {
    SET_PREVIOUS_NAVIGATION = 'setPreviousUrl',
    SET_CURRENT_NAVIGATION = 'setCurrentUrl',
}

export const navigationActions = createActionGroup({
    source: StoreFeatureNames.NAVIGATION,
    events: {
        [NavigationAction.SET_PREVIOUS_NAVIGATION]: props<{ previous: string }>(),
        [NavigationAction.SET_CURRENT_NAVIGATION]: props<{ current: string }>(),
    }
});
