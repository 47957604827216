import {createReducer, on} from '@ngrx/store';
import {userInitializedAction} from '../action/user-initialized-action';
import {IAccountDataInitialized} from '../interfaces/IPressUpStore';

export const userInitializedReducer = createReducer(
    {},
    on(userInitializedAction.setChoosingAfterModal, (state: IAccountDataInitialized, {choose}) => ({
        ...state,
        choose
    }))
);
